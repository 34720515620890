<template>
  <b-container fluid>
    <b-row align-h="around" class="text-center">
      <b-col cols="6">
        <b-img
          src="https://simg.nicepng.com/png/small/73-730154_open-default-profile-picture-png.png"
          rounded="circle"
          class="user_profile_photo-img"
        />
        <h1 v-if="userData">{{ userData.userName }}</h1>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
export default {
  props: {
    userData: {
      type: Object,
      require: false,
    },
  },
};
</script>

<style scoped>
.user_profile_photo-img {
  width: 150px;
  height: 150px;
}
</style>
