<template>
  <b-container class="user_personal_data-container">
    <!-- NAME -->
    <!-- Read Only -->
    <b-row v-if="!editable">
      <b-col>
        <h5>
          <b>{{ $t("views.userProfile.userAttributes.name") }}:</b>
        </h5>
      </b-col>
      <b-col cols="9">
        <h5 v-if="userProfile">{{ this.userProfile.name }}</h5>
      </b-col>
    </b-row>
    <!-- Editable -->
    <b-row v-else>
      <b-col>
        <b-form-group
          id="userdata-name"
          :label="$t('views.userProfile.userAttributes.name')"
          label-for="name"
        >
          <b-form-input
            id="name"
            :placeholder="
              $t('views.userProfile.userAttributes.nameInstructions')
            "
            v-model="name"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Email -->
    <!-- Read Only -->
    <b-row v-if="!editable">
      <b-col>
        <h5>
          <b>{{ $t("views.userProfile.userAttributes.email") }}</b>
        </h5>
      </b-col>
      <b-col cols="9">
        <h5 v-if="userProfile">{{ this.userProfile.mail }}</h5>
      </b-col>
    </b-row>
    <!-- Editable -->
    <b-row v-else>
      <b-col>
        <b-form-group
          id="userdata-email"
          :label="$t('views.userProfile.userAttributes.email')"
          label-for="email"
        >
          <b-form-input
            id="email"
            :placeholder="
              $t('views.userProfile.userAttributes.emailInstructions')
            "
            v-model="mail"
            required
          ></b-form-input>
        </b-form-group>
      </b-col>
    </b-row>

    <!-- Customer -->
    <!-- Read Only -->
    <b-row v-if="!editable">
      <b-col>
        <h5>
          <b>{{ $t("views.userProfile.userAttributes.customer") }}:</b>
        </h5>
      </b-col>
      <b-col cols="9">
        <h5 v-if="userProfile">{{ this.userProfile.customer }}</h5>
      </b-col>
    </b-row>
    <!-- Editable -->

    <!-- SMSAccount -->
    <!-- Read Only -->
    <b-row v-if="!editable">
      <b-col>
        <h5>
          <b>{{ $t("views.userProfile.userAttributes.SMSAccount") }}:</b>
        </h5>
      </b-col>
      <b-col cols="9">
        <h5 v-if="userProfile">
          {{ this.userProfile.smsAccount || $t("HeaderCase.None") }}
        </h5>
      </b-col>
    </b-row>
    <!-- Editable -->
    <b-row v-if="editable && !!!userProfile.smsAccount">
      <b-col>
        <b-form-group
          id="userdata-sms"
          :label="$t('views.userProfile.userAttributes.SMSAccount')"
          label-for="sms"
        >
          <b-link id="sms" @click="SMSLogin">{{
            $t("views.userProfile.userAttributes.link")
          }}</b-link>
        </b-form-group>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapState } from "vuex";
export default {
  props: {
    editable: {
      type: Boolean,
      require: true,
      default: false,
    },
  },
  methods: {
    SMSLogin() {
      const that = this;
      this.$oidcClient.getSignedIn().then(
        (signIn) => {
          if (signIn)
            this.$oidcClient.getLinkedUser().then((userInfo) => {
              that.$api.smsLink({
                token: userInfo.access_token,
              });
            });
          else {
            this.$oidcClient.linkedSignIn();
          }
        },
        (err) => {
          console.log(err);
        }
      );
    },
  },
  computed: {
    ...mapState("User", ["userProfile"]),
    name: {
      get() {
        return this.$store.state.User.userProfile.name;
      },
      set(value) {
        this.$store.commit("User/updateUserProfileName", value);
      },
    },
    mail: {
      get() {
        return this.$store.state.User.userProfile.mail;
      },
      set(value) {
        this.$store.commit("User/updateUserProfileMail", value);
      },
    },
    idCustomer: {
      get() {
        return this.$store.state.User.userProfile.idCustomer;
      },
      set(value) {
        this.$store.commit("User/updateUserProfileIdCustomer", value);
      },
    },
  },
  created() {
    if (this.$route.query.state) {
      this.$oidcClient.signinRedirect(this.$route).then((userInfo) => {
        this.$api
          .smsLink({
            token: userInfo.access_token,
          })
          .then(() => {
            this.$bvToast.toast(
              this.$t("views.userProfile.userAttributes.linkSuccess"),
              {
                title: this.$t("views.userProfile.userAttributes.success"),
                autoHideDelay: 5000,
                appendToast: true,
              }
            );
          });
      });
    }
  },
};
</script>

<style scoped>
.user_personal_data-container {
  margin-top: 40px;
  margin-bottom: 40px;
}
</style>
