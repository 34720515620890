<template>
  <div class="container">
    <b-row align-h="center">
      <b-col cols="8">
        <b-card class="h-100" :title="$t('views.userProfile.userProfile')">
          <div>
            <user-profile-photo :userData="userProfile"></user-profile-photo>
            <user-edit-options
              @onEdit="onEdit"
              v-show="!isEditable"
              @onResetPassword="onResetPassword"
            ></user-edit-options>

            <div v-if="isLoading" class="text-center my-2">
              <simple-loader />
            </div>
            <div v-else>
              <user-personal-data :editable="isEditable"></user-personal-data>

              <b-row align-h="center" v-show="isEditable">
                <b-col cols="4">
                  <b-button variant="danger" @click="onCancel">{{
                    $t("views.userProfile.cancel")
                  }}</b-button>
                  <b-button
                    variant="success"
                    @click="onSave"
                    :disabled="
                      !Boolean(userProfile.name) || !Boolean(userProfile.mail)
                    "
                    >{{ $t("general.save") }}</b-button
                  >
                </b-col>
              </b-row>
            </div>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { mapActions, mapState } from "vuex";

import UserEditOptions from "../components/UserProfile/UserEditOptions.vue";
import UserPersonalData from "../components/UserProfile/UserPersonalData.vue";
import UserProfilePhoto from "../components/UserProfile/UserProfilePhoto.vue";

export default {
  data() {
    return {
      editable: false,
      isLoading: false,
    };
  },
  components: {
    UserProfilePhoto,
    UserEditOptions,
    UserPersonalData,
  },
  computed: {
    ...mapState(["userName"]),
    ...mapState("User", ["userProfile"]),
    isEditable() {
      return this.editable;
    },
  },
  methods: {
    ...mapActions("User", [
      "getUserProfile",
      "updateUser",
      "getUser",
      "seflResetPassword",
    ]),
    onEdit() {
      this.editable = this.editable ? false : true;
    },
    onResetPassword() {
      this.isLoading = true;
      this.seflResetPassword({
        api: this.$api,
        Name: this.userName,
      }).then(() => {
        this.$bvToast.toast(
          this.$t("views.users.ResetingPasswordSuccessfully"),
          {
            variant: "success",
            title: this.$t("views.users.ResetingPassword"),
            autoHideDelay: 5000,
            appendToast: true,
          }
        );
        this.isLoading = false;
      });
    },

    onCancel() {
      this.editable = false;
      this.getUserProfile({
        api: this.$api,
        userName: this.userName,
      });
    },
    onSave() {
      this.isLoading = true;
      this.updateUser({
        api: this.$api,
        user: this.userProfile,
      }).then(() => {
        this.isLoading = this.editable = false;
      });
    },
  },
  created() {
    this.getUserProfile({
      api: this.$api,
      userName: this.userName,
    });
  },
};
</script>
